import React from "react"
import "./Introcard.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"

const IntroCard = props => {
  return (
    <>
      <div className="IntroCard-wrapper">
        <Container>
          <section className="IntroCard">
            <div className="content">
              <div className="p18">
                {props.html ? (
                  <>{parse(props.html)}</>
                ) : (
                  <>
                    {" "}
                    <p>{props.line1}</p> <p>{props.line2}</p>{" "}
                    <p>{props.line3}</p>{" "}
                  </>
                )}

              </div>
              <h5 className="position-relative">
                <div className="left-decor position-absolute">
                  <div className="star position-absolute">
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8123_50679)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.13923 7.82915C9.40503 6.41191 9.92579 5.09565 10.7124 3.88927C10.8688 3.6485 10.8013 3.3254 10.5593 3.16671C10.3185 3.01031 9.99537 3.07777 9.83668 3.3198C8.97763 4.64343 8.40565 6.08313 8.11365 7.63683C8.06044 7.91911 8.24747 8.19434 8.52975 8.24755C8.81432 8.2995 9.08602 8.11143 9.13923 7.82915Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.9784 5.5907C11.0824 6.1487 10.184 6.70213 9.29186 7.26698C9.04919 7.42121 8.97655 7.74395 9.13079 7.98662C9.28503 8.22929 9.60776 8.30193 9.85043 8.14769C10.7403 7.5841 11.6364 7.03193 12.5323 6.47393C12.7756 6.32236 12.8494 5.999 12.697 5.75532C12.5446 5.51165 12.2217 5.43609 11.9784 5.5907Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.6554 9.08753C12.4513 8.98738 11.2731 8.77182 10.0831 8.5567C9.79857 8.50475 9.52684 8.6928 9.47718 8.97611C9.42523 9.26068 9.61331 9.5324 9.89662 9.58206C11.1172 9.80416 12.3307 10.0242 13.57 10.1288C13.857 10.1526 14.1077 9.93737 14.1316 9.65039C14.1567 9.3657 13.9424 9.11136 13.6554 9.08753Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.6202 13.158C10.6772 11.6104 9.74872 9.92685 8.97717 8.28503C8.85265 8.02602 8.54352 7.91316 8.28097 8.03665C8.02071 8.15888 7.9091 8.47029 8.03133 8.73055C8.82047 10.4044 9.76779 12.1222 10.7284 13.7018C10.8781 13.947 11.201 14.0254 11.4462 13.8758C11.6914 13.7261 11.7699 13.4032 11.6202 13.158Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.18655 7.59532C7.52652 9.23225 6.9082 10.8909 6.15434 12.4872C6.03257 12.7476 6.14395 13.0585 6.40565 13.1825C6.66608 13.3043 6.97698 13.1929 7.09875 12.9325C7.86004 11.3173 8.48579 9.63969 9.15326 7.98378C9.25961 7.71694 9.13097 7.41255 8.86413 7.30621C8.59603 7.19757 8.2929 7.32848 8.18655 7.59532Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.00285 8.08259C6.89541 9.02478 5.74105 9.75164 4.48691 10.4649C4.2367 10.6054 4.1499 10.924 4.29042 11.1742C4.43448 11.4255 4.75082 11.5135 5.00206 11.3695C6.31563 10.6236 7.52005 9.86323 8.68065 8.87694C8.89864 8.68864 8.92672 8.36064 8.73716 8.14036C8.55115 7.92111 8.22084 7.89429 8.00285 8.08259Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.12328 8.06879C6.23475 8.14244 4.37892 8.66549 2.49349 8.66896C2.20743 8.66844 1.97377 8.90403 1.97451 9.19239C1.97524 9.48074 2.20962 9.71212 2.49569 9.71264C4.39277 9.70871 6.26024 9.18521 8.16397 9.11213C8.45186 9.09974 8.67592 8.85749 8.66707 8.57062C8.65467 8.28273 8.41243 8.05869 8.12328 8.06879Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.68476 8.29142C7.61359 6.96018 6.68541 5.55331 5.6938 4.16643C5.52585 3.9313 5.2015 3.8774 4.96637 4.04536C4.73124 4.21331 4.67735 4.53767 4.84531 4.7728C5.84572 6.17568 6.78852 7.59832 7.87203 8.9466C8.05348 9.16836 8.38296 9.20454 8.60598 9.02539C8.83003 8.84269 8.86746 8.51547 8.68476 8.29142Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.9442 8.93554C9.79078 8.62395 9.65301 8.32459 9.53308 8.00353C9.43142 7.73195 9.13075 7.59657 8.86147 7.69697C8.59218 7.79737 8.4545 8.09928 8.5549 8.36857C8.68786 8.72415 8.83968 9.05449 9.00944 9.39579C9.13624 9.65354 9.44869 9.7616 9.70747 9.63125C9.96522 9.50444 10.071 9.19329 9.9442 8.93554Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8123_50679">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(10.5156 0.976562) rotate(61.2016)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ellipse-dark position-absolute">
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="2.67957"
                        cy="2.2869"
                        r="2"
                        transform="rotate(61.2016 2.67957 2.2869)"
                        fill="#092047"
                      />
                    </svg>
                  </div>
                  <div className="ellipse-light position-absolute">
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="2.20691"
                        cy="2.81498"
                        r="2"
                        transform="rotate(61.2016 2.20691 2.81498)"
                        fill="#A7B0C0"
                      />
                    </svg>
                  </div>
                </div>
                {props.title}

                <div className="right-decor position-absolute">
                  <svg
                    width="43"
                    height="50"
                    viewBox="0 0 43 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8123_50691)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M28.1938 21.889C28.1202 20.9922 27.962 20.0268 27.5775 19.2125C27.1208 18.2453 26.3473 17.6953 25.5004 17.2387C24.4123 16.6523 23.2042 16.2119 22.1299 15.5661C21.7309 15.3267 21.6313 15.044 21.5861 14.9221C21.4721 14.6139 21.4931 14.3386 21.5771 14.0969C21.6762 13.8102 21.8939 13.4381 22.4543 13.3046C22.5345 13.2856 22.6544 13.2651 22.8096 13.2712C22.9233 13.2758 23.2631 13.3497 23.4082 13.3612C23.766 13.3884 24.121 13.4261 24.477 13.4675C25.6619 13.6053 26.6562 13.7679 27.844 13.5222C29.4564 13.1891 30.517 12.7073 31.1604 11.8422C31.7827 11.0057 32.0107 9.86806 32.0994 8.36048C32.1027 8.21565 32.1103 8.0735 32.1227 7.93413C32.1805 7.27444 32.7549 6.78214 33.4159 6.82568C34.0764 6.86909 34.5811 7.43264 34.5526 8.09408C34.5466 8.23213 34.5396 8.36793 34.5307 8.50168C34.4941 10.5611 35.6051 13.2525 36.7534 14.9506C37.1075 15.474 38.2396 16.4039 38.9021 17.2212C39.3908 17.8244 39.6582 18.427 39.6884 18.8813C39.7091 19.1858 39.6318 19.4173 39.532 19.5989C39.3871 19.8628 39.1716 20.0564 38.8803 20.1714C38.721 20.2338 38.5183 20.2771 38.279 20.2597C38.1638 20.2512 37.8845 20.1793 37.7584 20.1452C37.3065 20.1554 36.8572 20.1955 36.405 20.1914C35.3261 20.1817 34.5512 20.5203 33.5456 20.8997C32.6045 21.2544 31.9461 21.7293 31.3125 22.5139C31.2534 22.6222 30.7756 23.4956 30.5563 23.7906C30.2353 24.2214 29.8516 24.3652 29.6557 24.4022C29.3233 24.4654 29.0619 24.4114 28.8548 24.3259C28.5491 24.1996 28.3158 23.9852 28.1642 23.6745C28.0698 23.482 27.9997 23.2132 28.0185 22.8834C28.0325 22.6285 28.174 21.9801 28.1938 21.889ZM28.0088 15.9686C28.1169 15.951 28.2261 15.9307 28.3371 15.9078C30.6986 15.4199 32.1727 14.5633 33.115 13.2958L33.146 13.2532C33.5975 14.3894 34.1558 15.4578 34.7355 16.3151C34.9742 16.6681 35.5243 17.1979 36.0832 17.7607C34.848 17.8077 33.8881 18.167 32.6859 18.6201C31.7683 18.9664 31.0343 19.39 30.3723 19.9744C30.2363 19.3477 30.045 18.7336 29.7801 18.1724C29.3389 17.2381 28.7266 16.535 28.0088 15.9686Z"
                        fill="black"
                      />
                    </g>
                    <circle
                      cx="8.36942"
                      cy="17.0406"
                      r="2"
                      transform="rotate(-75.0786 8.36942 17.0406)"
                      fill="#092047"
                    />
                    <circle
                      cx="29.5022"
                      cy="46.4749"
                      r="2"
                      transform="rotate(-75.0786 29.5022 46.4749)"
                      fill="#A7B0C0"
                    />
                    <defs>
                      <clipPath id="clip0_8123_50691">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                          transform="translate(19.4492 22.0631) rotate(-75.0786)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="line position-absolute">
                  <svg
                    width="188"
                    height="12"
                    viewBox="0 0 188 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M186.508 6.63422C149.475 9.96048 111.934 7.02763 74.87 7.05392C50.3611 7.07697 25.8375 7.78257 1.348 7.71217C0.613797 7.70876 0.023076 7.00963 0.029175 6.1445C0.0357171 5.27939 0.6368 4.58182 1.37056 4.58521C25.8574 4.65557 50.3783 3.94992 74.8846 3.92682C111.882 3.89927 149.352 6.83601 186.319 3.51371C187.052 3.44933 187.687 4.09196 187.742 4.95302C187.792 5.814 187.237 6.56455 186.508 6.63422Z"
                      fill="#FF0055"
                    />
                  </svg>
                </div>
              </h5>
            </div>
          </section>
        </Container>
      </div>
    </>
  )
}

export default IntroCard
