import React from "react"
import { Container } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import "./Advantage.scss"

const Advantage = props => {
  const icon = props.icon //array of object
  const heading = props.heading // array
  const description = props.description // array

  return (
    <div className="Advantage-box">
      <Slide delay="200">
        <Container className="Layout-container">
          <div
            className={`advantage-inner ${props.title ? "titleBox" : ""} ${
              props.className ? props.className : ""
            }`}
          >
            {props.title && (
              <div className="column-box">
                <h2
                  className="title"
                  style={{
                    maxWidth: `${props.maxTitleWidth && props.maxTitleWidth}`,
                  }}
                >
                  {props.title}
                </h2>
              </div>
            )}
            {icon.map((icn, index) => {
              return (
                <div className="column-box">
                  <div className="d-flex align-items-center">
                    <img src={icn.url} alt="img" className="icon" />
                    <h3 className="heading fw-bold">{heading[index]}</h3>
                  </div>
                  <div className="p16 p">
                    <p className="content">{description[index]}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </Slide>
    </div>
  )
}

export default Advantage
